import { Component, Inject, ViewChild, OnInit, Input } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MatAccordion, fadeInContent, MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AddConvictionModel } from './addConvitionModel';
import { UIDataService } from './uiData.service';
import { Observable, Subscription, concat } from 'rxjs';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { DriverDetailInformation, conviction, withdrawal, GeneralInfo, ResidenceInfo, LicenseDetail, MedicalDetail } from './DriverDetailInformation';
import { UserAccountService } from '../account/userAccount.service';
import { MessageDialogComponent } from '../convictionReview/conReview.component';
import { share } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService, LoginModel } from '.././_services/authentication.service';
import { Suffix } from './driverRecordResponse/addDriverInfo.component';
import { MatInputModule } from '@angular/material/input';
import { User } from '../account/user';
import { SessionService } from '../_services/session.service';

function dateErrorValidator(control: AbstractControl): { [key: string]: boolean } | null {
  let validFormat = new RegExp("^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{4})*$");
  let date: string[] = control.value.toString().split("/", 3);
  if (!validFormat.test(control.value.toString())) {
    return { 'dateError': true };
  }
  else {
    switch (Number(date[0])) {
      case 1: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 2: {
        if (Number(date[1]) == 29) {
          if ((Number(date[2]) % 4 == 0 && Number(date[2]) % 100 != 0) || (Number(date[2]) % 400 == 0)) {
            return null;
          }
          else {
            return { 'dateError': true };
          }
        }
        else {
          if (Number(date[1]) >= 1 && Number(date[1]) <= 28) {
            return null;
          }
          else {
            return { 'dateError': true };
          }
        }
      }
      case 3: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 4: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 5: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 6: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 7: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 8: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 9: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 10: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 11: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 12: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
    }
  }
  return null;
}
function invalidElemValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (control.value == "") {
    return { 'invalidElem': true };

  }
  else {
    return null;
  }
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-driverDetail-component',
  templateUrl: './driverDetail.component.html',
  styleUrls: ['./driverDetail.component.css']
})


export class driverDetailComponent implements OnInit {
  @ViewChild('myaccordion') myPanels: MatAccordion;
  multi = true;
  public commentErrorMessageC: string = "* Please enter a reason for deleting/negating the Conviction.";
  public commentErrorMessageW: string = "* Please enter a reason for deleting/negating the Withdrawal.";
  private DataChanged: boolean = false;
  public stateDropDown: boolean = false;
  public driverInformation = new DriverDetailInformation();
  public driverObservable: Observable<DriverDetailInformation>;
  public statesObservable: Observable<State[]>;
  public CountrystatesObservable: Observable<State[]>;
  public Countrystates: State[];
  public driverstatesObservable: Observable<State[]>;
  public CountriesObservable: Observable<Country[]>;
  public LinkConvictionWithdrawal: LinkConvictionWithdrawalDTO;
  public LinkConvictionWithdrawalList: LinkConvictionWithdrawalDTO[] = [];
  public WithdrawalLinks: LinkConvictionWithdrawalDTO[] = [];
  public suffixData: Observable<Suffix[]>;
  public commentsObservable: Observable<Comment[]>;
  public IsDisabled: boolean;
  public IsReview: boolean;
  displayedWithdrawalData: string[];
  displayedConvictionData: string[];
  public convictionData = new conviction();
  public withdrawalData = new withdrawal();
  public isError: boolean;
  public errorMessage: string;
  public SelectedConvictions: conviction[] = [];
  public SelectedWithdrawals: withdrawal[] = [];
  public showCommentDropDown: boolean = false;
  public commentText: boolean = false;
  public showCommentDropDownW: boolean = false;
  public commentTextW: boolean = false;
  public commentOptions: string[] = [];
  public additionalCommentsC: boolean;
  public additionalCommentsW: boolean;
  public withdrawalComments: string = "";
  public convictionComments: string = "";
  constructor(private spinner: NgxSpinnerService, private idleTimeoutSvc: SessionService, private uiService: UIDataService, private UAS: UserAccountService, private router: Router, _http: HttpClient, public dialog: MatDialog, @Inject('BASE_URL') _baseUrl: string, private route: ActivatedRoute, private location: Location, private authService: AuthenticationService) {
    this.IsReview = false;
    this.baseUrl = _baseUrl;
    this.http = _http;
    this.additionalCommentsW = false;
    this.additionalCommentsC = false;
    this.driverstatesObservable = this.http.get<State[]>(this.baseUrl + 'api/Data/GetResidentStateCodes').pipe(share());
    this.CountriesObservable = this.http.get<Country[]>(this.baseUrl + 'api/Data/GetCountryCodes').pipe(share());
    this.statesObservable = this.http.get<State[]>(this.baseUrl + 'api/Data/GetJurisdictionCodes').pipe(share());
    this.CountrystatesObservable = this.http.get<State[]>(this.baseUrl + 'api/Data/CountryStates').pipe(share());
    this.DOBForm.valueChanges.subscribe(g => {
      this.DOBFormControl.setValue(g.toLocaleDateString("en-US"), { emitEvent: false, onlySelf: true });
      this.driverInformation.generalInfo.driverDob = g.toLocaleDateString("en-US");
      this.DataChanged = true;
    });
    this.DOBFormControl.valueChanges.subscribe(g => {
      if (this.DOBFormControl.valid) {
        this.DOBForm.patchValue(new Date(g), { emitEvent: false, onlySelf: true });
        this.driverInformation.generalInfo.driverDob = g;
        this.DataChanged = true;
      }
      else {
        this.DOBForm.patchValue('', { emitEvent: false, onlySelf: true });
      }
    });
    this.suffixData = this.http.get<Suffix[]>(this.baseUrl + 'api/Data/SuffixCodes').pipe(share());
    this.authService.currentUser.subscribe(message => {
      this.LoginState = message.state, this.UserID = message.userID
    });
    this.DriverFirstName.valueChanges.subscribe(g => { this.DataChanged = true; });
    this.DriverLastName.valueChanges.subscribe(g => { this.DataChanged = true; });
    this.Zip.valueChanges.subscribe(g => { this.DataChanged = true; });
    this.State.valueChanges.subscribe(g => { this.DataChanged = true; });
    this.City.valueChanges.subscribe(g => { this.DataChanged = true; });
    this.Country.valueChanges.subscribe(g => { this.DataChanged = true; });
    this.County.valueChanges.subscribe(g => { this.DataChanged = true; });
    this.DriverMiddleName.valueChanges.subscribe(g => { this.DataChanged = true; });
    this.DriverSuffix.valueChanges.subscribe(g => { this.DataChanged = true; });
    this.StreetAddr1.valueChanges.subscribe(g => { this.DataChanged = true; });
    this.StreetAddr2.valueChanges.subscribe(g => { this.DataChanged = true; });
    this.Country.valueChanges.subscribe(g => {
      let str: string;
      this.CountriesObservable.subscribe(y => {
        str = y.find(x => x.jurisdictionDesc == g).type;
        if (str == "MX" || str == "US") {
          this.CountrystatesObservable.subscribe(x => {
            this.Countrystates = x.filter(z => z.jurisdictionGroup == str);
          });
          this.stateDropDown = true;
        }
        else {
          this.stateDropDown = false;
        }
      });

    });
    this.IsDisabled = false;
    this.isError = false;
    this.errorMessage = "";
    this.commentErrorMessageW = "* Please enter a reason for deleting/negating the Withdrawal.";
    this.commentErrorMessageC = "* Please enter a reason for deleting/negating the Conviction.";
    this.WithdrawalCommentFormControl.valueChanges.subscribe(g => {
      this.withdrawalComments = g;
      this.commentsObservable.subscribe(y => {
        if (y.find(x => x.id == g).freeFormat == true && this.LoginState == "OR") {
          this.commentsFormWithdrawalText.setValidators([invalidElemValidator]);
          this.commentsFormWithdrawalText.updateValueAndValidity();
          this.commentErrorMessageW = "* Additional comment required. Please enter a reason for deleting/negating the Withdrawal.";
        }
        else {
          this.commentsFormWithdrawalText.clearValidators();
          this.commentsFormWithdrawalText.updateValueAndValidity();
          this.commentErrorMessageW = "* Please enter a reason for deleting/negating the Withdrawal.";
        }
      });
    });
    //----------- Bug 4263 ------------ User Requirement removed --------------
    //this.ConvictionCommentFormControl.valueChanges.subscribe(g => {
    //  this.convictionComments = g;
    //  this.commentsObservable.subscribe(y => {
    //    if (y.find(x => x.id == g).freeFormat == true && this.LoginState == "OR") {
    //      this.commentsFormConvictionText.setValidators([invalidElemValidator]);
    //      this.commentsFormConvictionText.updateValueAndValidity();

    //      this.commentErrorMessageC = "* Additional comment required. Please enter a reason for deleting/negating the Conviction.";
    //    }
    //    else {
    //      this.commentsFormConvictionText.clearValidators();
    //      this.commentsFormConvictionText.updateValueAndValidity();

    //      this.commentErrorMessageC = "* Please enter a reason for deleting/negating the Conviction.";
    //    }
    //  });
    //});
    //this.commentsFormWithdrawalText.valueChanges.subscribe(g => {
    //  if (g != "" || g != " " || g != null) {
    //    this.additionalCommentsW = true;
    //  }
    //});
  }
  http: HttpClient;
  baseUrl: string;
  DriverLicense: string;
  DriverState: string;
  LoginState: string;
  UserID: string;
  DataSource: string = "";
  userInfo: Observable<User>;
  @Input() matAutosizeMinRows: 3;
  @Input() matAutosizeMaxRows: 3;

  ngOnInit(): void {
    this.authService.currentUser.subscribe(message => {
      this.LoadNegateComments(message.state);
    });

    this.getDriverDetail(false);
  }
  private LoadNegateComments(state: string) {
    if (state == "MI") {
      this.showCommentDropDown = true;
      this.commentText = false;
      this.showCommentDropDownW = true;
      this.commentTextW = false;
      let Params = new HttpParams();
      Params = Params.append("state", state);
      this.commentsObservable = this.http.get<Comment[]>(this.baseUrl + 'api/Data/NegateComments', { params: Params }).pipe(share());
    }
    else if (state == "OR") {
      this.showCommentDropDown = true;
      this.commentText = true;
      this.showCommentDropDownW = true;
      this.commentTextW = true;
      let Params = new HttpParams();
      Params = Params.append("state", state);
      this.commentsObservable = this.http.get<Comment[]>(this.baseUrl + 'api/Data/NegateComments', { params: Params }).pipe(share());
    }
    else if (state == "CA") {
      this.showCommentDropDown = false;
      this.commentTextW = false;
      this.showCommentDropDownW = true;
      this.commentText = true;
      let Params = new HttpParams();
      Params = Params.append("state", state);
      this.commentsObservable = this.http.get<Comment[]>(this.baseUrl + 'api/Data/NegateComments', { params: Params }).pipe(share());
    }
    else {
      this.showCommentDropDown = false;
      this.commentText = true;
      this.showCommentDropDownW = false;
      this.commentTextW = true;
    }
  }

  public getDriverDetail(_ReqType) {
    this.SelectedWithdrawals = [];
    this.SelectedConvictions = [];
    this.LinkConvictionWithdrawalList = [];
    this.isError = false;
    this.errorMessage = "";
    this.spinner.show();
    this.authService.currentUser.subscribe(g => {
      if (g != null) {
        var Account = new LoginModel();
        Account.Password = "xxxxxxxxx";
        Account.UserID = g.userID;
        this.userInfo = this.http.post<User>(this.baseUrl + 'login/Account/RefreshToken', Account);
        this.userInfo.subscribe(g => {
          localStorage.removeItem('currentUser');
          localStorage.setItem('currentUser', JSON.stringify(g));
          this.idleTimeoutSvc.resetTimer();
        });
      }
    });
    this.displayedWithdrawalData = ['Select', 'driverState', 'driverLicenseNumber', 'withdrawalEffectiveDate', 'withdrawalEligibilityDate', 'withdrawalReinstateDate', 'withdrawalJurisdictionCode', 'withdrawalId', 'linkage', 'withdrawalAcdCode', 'withdrawalActionTypeCode', 'withdrawalBasisCode', 'withdrawalExtentCode', 'withdrawalDueProcStatCode', 'withdrawalJurisdictionLocator', 'withdrawalReasonReference', 'withdrawalUserId', 'withdrawalDatePosted'];
    this.displayedConvictionData = ['Actions', 'driverState', 'driverLicenseNumber', 'convictionCitationDate', 'convictionDate', 'convictionId', 'convictionJurisdictionCode', 'convictionCourtType', 'convictionAcdCode', 'convictionAcdCodeDetail', 'convictionDriverCdlHolder', 'convictionCommercialVehicle', 'convictionHazmatVehicle', 'convictionOffenseLocator', 'convictionJurisOffenseReference', 'convictionCategory', 'convictionUserId', 'convictionDatePosted'];
    this.DriverLicense = this.route.snapshot.paramMap.get('license');
    this.DriverState = this.route.snapshot.paramMap.get('state');
    let Params = new HttpParams();
    Params = Params.append("DrivingLicenseNumber", this.DriverLicense);
    Params = Params.append("DriverState", this.DriverState);
    Params = Params.append("User", this.UserID);
    this.driverObservable = this.http.get<DriverDetailInformation>(this.baseUrl + 'api/Data/GetDriverInfo', { params: Params }).pipe(share());
    this.driverObservable.subscribe(message => {
      if (message.isError) {
        this.driverInformation = message;
        this.Redirect(message.errorMessage);
      }
      else {
        this.driverInformation = message;
        this.commentsFormControl = new FormControl(message.history, []);
        this.spinner.hide();
        this.DataSource = message.messageToUI;//this.CDLIndicator(message.dataSource);
        if (message.dataSource == "eLicencia" || message.dataSource == "MX") {
          this.IsDisabled = true;
          this.DriverLastName.patchValue(message.generalInfo.driverLastName);
          this.DriverFirstName.patchValue(message.generalInfo.driverFirstName);
          this.DriverMiddleName.patchValue(message.generalInfo.driverMiddleName);
          this.DriverSuffix.patchValue(message.generalInfo.driverSuffix);
          this.StreetAddr1.patchValue(message.residenceInfo.driverStreetAddress1);
          this.StreetAddr2.patchValue(message.residenceInfo.driverStreetAddress2);
          this.City.patchValue(message.residenceInfo.driverMailingCity);
          this.County.patchValue(message.residenceInfo.driverMailingCounty);
          this.Country.patchValue(message.residenceInfo.driverMailingCountry);
          this.State.patchValue(message.residenceInfo.driverMailingState);
          this.Zip.patchValue(message.residenceInfo.driverMailingZipcode);
          this.DOBFormControl.setValue(new Date(message.generalInfo.driverDob).toLocaleDateString("en-US"));
          this.DriverFirstName.disable();
          this.DriverLastName.disable();
          this.DriverMiddleName.disable();
          this.Zip.disable();
          this.DriverSuffix.disable();
          this.City.disable();
          this.County.disable();
          this.State.disable();
          this.Country.disable();
          this.StreetAddr1.disable();
          this.StreetAddr2.disable();
          this.DOBFormControl.disable();
          this.DOBForm.disable();
        }
        else {
          this.IsDisabled = false;
          this.DOBFormControl.setValue(new Date(message.generalInfo.driverDob).toLocaleDateString("en-US"));
          this.DriverLastName.patchValue(message.generalInfo.driverLastName);
          this.DriverFirstName.patchValue(message.generalInfo.driverFirstName);
          this.DriverMiddleName.patchValue(message.generalInfo.driverMiddleName);
          this.DriverSuffix.patchValue(message.generalInfo.driverSuffix);
          this.StreetAddr1.patchValue(message.residenceInfo.driverStreetAddress1);
          this.StreetAddr2.patchValue(message.residenceInfo.driverStreetAddress2);
          this.City.patchValue(message.residenceInfo.driverMailingCity);
          this.County.patchValue(message.residenceInfo.driverMailingCounty);
          this.Country.patchValue(message.residenceInfo.driverMailingCountry);
          this.State.patchValue(message.residenceInfo.driverMailingState);
          this.Zip.patchValue(message.residenceInfo.driverMailingZipcode);
          this.DataChanged = false;
        }
      }
    });
    window.scrollTo(0, 0);
  }

  public Redirect(errorMesage: string) {
    if (errorMesage == "REQUESTED RECORD NOT FOUND" || errorMesage == "DRIVER IS NOT A CDL HOLDER") {
      this.uiService.SendErrorMessage("Driver is not a CDL Holder. If CMV = Yes, add to FCWD. If CMV = No, do not add to FCWD");
      this.driverInformation.errorMessage = "Driver is not a CDL Holder. If CMV = Yes, add to FCWD. If CMV = No, do not add to FCWD";
    }
    else if (errorMesage == "Record Not Found In FCWD") {
      this.uiService.SendErrorMessage("If CMV = Yes, add to FCWD. If CMV = No, do not add to FCWD");
      this.driverInformation.errorMessage = "If CMV = Yes, add to FCWD. If CMV = No, do not add to FCWD";
    }
    else if (errorMesage == "MESSAGE TIMED OUT" || errorMesage == "The Message could not be sent to the endpoint system.") {
      this.uiService.SendErrorMessage("CDL Holder cannot be determined. If CMV = Yes, add to FCWD. If CMV = No, wait until a response is received.");
      this.driverInformation.errorMessage = "CDL Holder cannot be determined. If CMV = Yes, add to FCWD. If CMV = No, wait until a response is received.";
    }
    else {
      this.uiService.SendErrorMessage(errorMesage);
    }
    this.statesObservable.subscribe(message => this.driverInformation.stateName = message.find(msg => msg.jurisdictionCode == this.driverInformation.driverState).jurisdictionDesc);
    this.UAS.currentUser.subscribe(message => { this.driverInformation.userID = message; });
    this.uiService.SendDriverInfo(this.driverInformation);
    var myurl1 = `${"/driverResponse"}`;
    this.router.navigateByUrl(myurl1);
  }

  public CDLIndicator(message: string) {
    switch (message) {
      case 'FCWD':
        return "Data is from FCWD. If CMV = Yes, add to FCWD. If CMV = No, do not add to FCWD.";
      case 'eLicencia':
        return "Driver is a CDL Holder. Add conviction to FCWD.";
      case 'MX DID NOT SUPPLY DATA BECAUSE CDL NOT VALID IN U.S.':
        return "MX did not supply data, but driver is a CDL Holder. Add conviction to FCWD.";
      default:
        return message;
    }
  }
  public CheckAccess(Jurisdiction: string) {
    return (this.LoginState == Jurisdiction) ? false : true;
  }

  public expand() {
    this.myPanels.openAll();
  }

  public collapse() {
    this.myPanels.closeAll();
  }

  public UpdateDriverInfo() {
    this.authService.currentUser.subscribe(g => {
      if (g != null) {
        var Account = new LoginModel();
        Account.Password = "xxxxxxxxx";
        Account.UserID = g.userID;
        this.userInfo = this.http.post<User>(this.baseUrl + 'login/Account/RefreshToken', Account);
        this.userInfo.subscribe(g => {
          localStorage.removeItem('currentUser');
          localStorage.setItem('currentUser', JSON.stringify(g));
          this.idleTimeoutSvc.resetTimer();
        });
      }
    });
    this.driverInformation.generalInfo.driverFirstName = this.DriverFirstName.value;
    this.driverInformation.generalInfo.driverLastName = this.DriverLastName.value;
    this.driverInformation.generalInfo.driverMiddleName = this.DriverMiddleName.value;
    this.driverInformation.generalInfo.driverDob = this.DOBFormControl.value;
    this.driverInformation.generalInfo.driverSuffix = this.DriverSuffix.value;
    this.driverInformation.residenceInfo.driverStreetAddress1 = this.StreetAddr1.value;
    this.driverInformation.residenceInfo.driverStreetAddress2 = this.StreetAddr2.value;
    this.driverInformation.residenceInfo.driverMailingCity = this.City.value;
    this.driverInformation.residenceInfo.driverMailingCounty = this.County.value;
    this.driverInformation.residenceInfo.driverMailingCountry = this.Country.value;
    this.driverInformation.residenceInfo.driverMailingState = this.State.value;
    this.driverInformation.residenceInfo.driverMailingZipcode = this.Zip.value;
    this.driverInformation.driverLicense = this.DriverLicense;
    this.driverInformation.driverState = this.DriverState;
    if (this.DriverLastName.valid && this.DOBFormControl.valid) {
      this.spinner.show();
      this.http.post<DriverDetailInformation>(this.baseUrl + 'api/Data/UpdateDriverInfo', this.driverInformation).subscribe(
        data => {
          if (data.isError) {
            this.spinner.hide();
            window.scrollTo(0, 0);
            this.isError = data.isError;
            this.errorMessage = data.errorMessage;
            this.driverInformation = data;
          }
          else {
            this.spinner.hide();
            this.DataChanged = false;
          }
        },
        error => {
          this.spinner.hide();
          window.scrollTo(0, 0);
        }
      );
    }
    else {
      window.scrollTo(0, 0);
    }
  }

  public addConviction(driverLicenseNumber: string, driverState: string) {
    this.authService.currentUser.subscribe(g => {
      if (g != null) {
        var Account = new LoginModel();
        Account.Password = "xxxxxxxxx";
        Account.UserID = g.userID;
        this.userInfo = this.http.post<User>(this.baseUrl + 'login/Account/RefreshToken', Account);
        this.userInfo.subscribe(g => {
          localStorage.removeItem('currentUser');
          localStorage.setItem('currentUser', JSON.stringify(g));
          this.idleTimeoutSvc.resetTimer();
        });
      }
    });
    if (this.DataChanged && !this.IsDisabled) {
      const dialogRef = this.dialog.open(MessageDialogComponent, { width: '400px', data: { header: 'Confirmation', message: 'Driver information has been changed. Do you want to save this change?', buttonOk: true, buttonClose: true, action: true, buttonOkLabel: 'Yes', buttonCloseLabel: 'No' } });
      dialogRef.afterClosed().subscribe(result => {
        if (result == true && result != undefined) {
          if (this.DriverLastName.valid && this.DOBFormControl.valid) {
            this.UpdateDriverInfo();
            this.spinner.show();
            this.UAS.currentUser.subscribe(message => { this.driverInformation.userID = message; });
            this.driverInformation.driverState = driverState;
            this.driverInformation.driverLicense = driverLicenseNumber;
            this.driverInformation.action = "Add Conviction";
            this.uiService.SendConviction(this.driverInformation);
            let myurl = `${"/addConviction"}`;
            this.router.navigateByUrl(myurl).then(e => {
              if (e) {
                this.spinner.hide();
              } else {
                this.spinner.hide();
              }
            });;
          }
          else {
            window.scrollTo(0, 0);
          }
        }
        else {
          this.spinner.show();
          this.UAS.currentUser.subscribe(message => { this.driverInformation.userID = message; });
          this.driverInformation.driverState = driverState;
          this.driverInformation.driverLicense = driverLicenseNumber;
          this.driverInformation.action = "Add Conviction";
          this.uiService.SendConviction(this.driverInformation);
          let myurl = `${"/addConviction"}`;
          this.router.navigateByUrl(myurl).then(e => {
            if (e) {
              this.spinner.hide();
            } else {
              this.spinner.hide();
            }
          });;
        }
      });
    }
    else {
      this.spinner.show();
      this.UAS.currentUser.subscribe(message => { this.driverInformation.userID = message; });
      this.driverInformation.driverState = driverState;
      this.driverInformation.driverLicense = driverLicenseNumber;
      this.driverInformation.action = "Add Conviction";
      this.uiService.SendConviction(this.driverInformation);
      let myurl = `${"/addConviction"}`;
      this.router.navigateByUrl(myurl).then(e => {
        if (e) {
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      });;
    }
  }
  public addWithdrawal(driverLicenseNumber: string, driverState: string) {
    this.authService.currentUser.subscribe(g => {
      if (g != null) {
        var Account = new LoginModel();
        Account.Password = "xxxxxxxxx";
        Account.UserID = g.userID;
        this.userInfo = this.http.post<User>(this.baseUrl + 'login/Account/RefreshToken', Account);
        this.userInfo.subscribe(g => {
          localStorage.removeItem('currentUser');
          localStorage.setItem('currentUser', JSON.stringify(g));
          this.idleTimeoutSvc.resetTimer();
        });
      }
    });
    if (this.DataChanged && !this.IsDisabled) {
      const dialogRef = this.dialog.open(MessageDialogComponent, { width: '400px', data: { header: 'Confirmation', message: 'Driver information has been changed. Do you want to save this change?', buttonOk: true, buttonClose: true, action: true, buttonOkLabel: 'Yes', buttonCloseLabel: 'No' } });
      dialogRef.afterClosed().subscribe(result => {
        if (result == true && result != undefined) {
          if (this.DriverLastName.valid && this.DOBFormControl.valid) {
            this.UpdateDriverInfo();
            this.spinner.show();
            this.UAS.currentUser.subscribe(message => { this.driverInformation.userID = message; });
            this.driverInformation.driverState = driverState;
            this.driverInformation.driverLicense = driverLicenseNumber;
            this.driverInformation.action = "Add Withdrawal";
            this.driverInformation.convictions = this.SelectedConvictions;
            this.uiService.sendWithdrawal(this.driverInformation);
            let myurl = `${"/addWithdrawal"}`;
            this.router.navigateByUrl(myurl).then(e => {
              if (e) {
                this.spinner.hide();
              } else {
                this.spinner.hide();
              }
            });
          }
          else {
            window.scrollTo(0, 0);
          }
        }
        else {
          this.spinner.show();
          this.UAS.currentUser.subscribe(message => { this.driverInformation.userID = message; });
          this.driverInformation.driverState = driverState;
          this.driverInformation.driverLicense = driverLicenseNumber;
          this.driverInformation.action = "Add Withdrawal";
          this.driverInformation.convictions = this.SelectedConvictions;
          this.uiService.sendWithdrawal(this.driverInformation);
          let myurl = `${"/addWithdrawal"}`;
          this.router.navigateByUrl(myurl).then(e => {
            if (e) {
              this.spinner.hide();
            } else {
              this.spinner.hide();
            }
          });
        }
      });
    }
    else {
      this.spinner.show();
      this.UAS.currentUser.subscribe(message => { this.driverInformation.userID = message; });
      this.driverInformation.driverState = driverState;
      this.driverInformation.driverLicense = driverLicenseNumber;
      this.driverInformation.action = "Add Withdrawal";
      this.driverInformation.convictions = this.SelectedConvictions;
      this.uiService.sendWithdrawal(this.driverInformation);
      let myurl = `${"/addWithdrawal"}`;
      this.router.navigateByUrl(myurl).then(e => {
        if (e) {
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      });
    }

  }
  public pushConvictionData(conv: conviction) {
    if (this.convictionData.convictionId != conv.convictionId) {
      this.convictionData = conv;
    }
    else {
      this.convictionData = null;
    }
  }
  public deleteNegateConviction(driverLicenseNumber: string, driverState: string, element: HTMLElement) {
    //#BUG 5595 FIXED check and see if HQ account is updating on PRODUCTION       
    var userState = this.authService.currentUserValue.state;
    var env = this.authService.currentUserValue.environment;

    if (userState.toUpperCase() == "HQ" ) {
      this.driverInformation.errorMessage = "HQ users are unable to create convictions, withdrawals or negates.";
      this.driverInformation.isError = true;
      window.scrollTo(0, 0);
    }
    else {
      if (this.SelectedConvictions.length == 1) {
        if ((this.showCommentDropDown/* && this.commentText*/ && this.LoginState == "OR" && this.ConvictionCommentFormControl.value == "" /*&& (this.commentsFormConvictionText.value == "" || this.commentsFormConvictionText.invalid)) || (this.LoginState == "OR" && this.commentsFormConvictionText.invalid)*/)) {
          this.ConvictionCommentFormControl.setValidators([invalidElemValidator]);
          this.ConvictionCommentFormControl.updateValueAndValidity();
          //this.commentsFormConvictionText.markAsTouched();
          this.ConvictionCommentFormControl.markAsTouched();
          element.scrollIntoView();
        }
        else if (this.showCommentDropDown && !this.commentText && this.LoginState == "MI" && this.ConvictionCommentFormControl.value == "") {
          this.ConvictionCommentFormControl.setValidators([invalidElemValidator]);
          this.ConvictionCommentFormControl.updateValueAndValidity();
          this.ConvictionCommentFormControl.markAsTouched();
          element.scrollIntoView();
        }
        else if (!this.showCommentDropDown && this.commentText && this.commentsFormConvictionText.value == "") {
          this.commentsFormConvictionText.setValidators([invalidElemValidator]);
          this.commentsFormConvictionText.updateValueAndValidity();
          this.commentsFormConvictionText.markAsTouched();
          element.scrollIntoView();
        }
        else {
          this.ConvictionCommentFormControl.clearValidators();
          this.ConvictionCommentFormControl.updateValueAndValidity();
          this.commentsFormConvictionText.clearValidators();
          this.commentsFormConvictionText.updateValueAndValidity();
          const dialogRef = this.dialog.open(MessageDialogComponent, { width: '400px', data: { header: 'Confirmation', message: 'Are you sure you want to delete/negate the conviction?', buttonOk: true, buttonClose: true, action: true, buttonOkLabel: 'Continue', buttonCloseLabel: 'Cancel' } });
          dialogRef.afterClosed().subscribe(result => {
            if (result == true && result != undefined) {
              this.spinner.show();
              this.UAS.currentUser.subscribe(message => { this.driverInformation.userID = message; });
              this.driverInformation.driverState = driverState;
              this.driverInformation.driverLicense = driverLicenseNumber;
              this.driverInformation.convictionData = this.SelectedConvictions[0];
              this.driverInformation.convictionData.convictionNotes = (this.convictionComments + " " + this.commentsFormConvictionText.value).trim();
              this.http.post<DriverDetailInformation>(this.baseUrl + 'api/Data/NegateConviction', this.driverInformation).subscribe(
                data => {
                  this.spinner.hide();
                  if (data.isError) {
                    window.scrollTo(0, 0);
                    this.isError = data.isError;
                    this.errorMessage = data.errorMessage;
                    this.driverInformation = data;
                  }
                  else {
                    this.commentsFormConvictionText = new FormControl('', [Validators.maxLength(500)]);
                    this.ConvictionCommentFormControl.setValue('');
                    for (let i in this.SelectedConvictions) {
                      this.SelectedConvictions.pop();
                    }
                    this.getDriverDetail(false);
                  }
                },
                error => {
                  this.spinner.hide();
                }
              );
            }
            else {
              this.ConvictionCommentFormControl.clearValidators();
              this.ConvictionCommentFormControl.updateValueAndValidity();
              this.commentsFormConvictionText.clearValidators();
              this.commentsFormConvictionText.updateValueAndValidity();
            }
          });
        }
      }
      else if (this.SelectedConvictions.length == 0) {
        const dialogRef = this.dialog.open(MessageDialogComponent, {
          width: '400px', data: {
            header: 'Error', message: 'No Convictions have been selected. Please select the Conviction you wish to negate/delete.', buttonOk: true, buttonClose: false, action: true, buttonOkLabel: 'Close'
          }
        });
      }
      else {
        const dialogRef = this.dialog.open(MessageDialogComponent, {
          width: '400px', data: {
            header: 'Error', message: 'Multiple Convictions have been selected. Please select only one Conviction you wish to negate/delete.', buttonOk: true, buttonClose: false, action: true, buttonOkLabel: 'Close'
          }
        });
      }
    }   
  }

  public LinkConvctionWithdrawal(DriverLicense: string, DriverState: string) {
    //#BUG 5595 FIXED check and see if HQ account is updating on PRODUCTION       
    var userState = this.authService.currentUserValue.state;
    var env = this.authService.currentUserValue.environment;

    if (userState.toUpperCase() == "HQ" ) {
      this.driverInformation.errorMessage = "HQ users are unable to create convictions, withdrawals or negates.";
      this.driverInformation.isError = true;
      window.scrollTo(0, 0);
    }
    else {
      var acdcodes: Array<string> = ['W00', 'W27', 'W28', 'W72', 'W80', 'W81', 'W82'];
      if (this.SelectedWithdrawals.length == 0) {
        const dialogRef = this.dialog.open(MessageDialogComponent, { width: '600px', data: { header: 'Error', message: 'No Withdrawals have been selected. Please select the Withdrawal you wish to update.', buttonOk: true, buttonClose: false, action: true, buttonOkLabel: 'Close', buttonCloseLabel: 'Cancel' } });
      }
      else if (this.SelectedWithdrawals.length > 1) {
        const dialogRef = this.dialog.open(MessageDialogComponent, { width: '600px', data: { header: 'Error', message: 'Please select only one Withdrawal at a time to link Conviction(s).', buttonOk: true, buttonClose: false, action: true, buttonOkLabel: 'Close', buttonCloseLabel: 'Cancel' } });
      }
      else if (acdcodes.indexOf(this.SelectedWithdrawals[0].withdrawalAcdCode) > -1) {
        const dialogRef = this.dialog.open(MessageDialogComponent, { width: '600px', data: { header: 'Error', message: 'This ACD Code does not allow a Linked Conviction.', buttonOk: true, buttonClose: false, action: true, buttonOkLabel: 'Close', buttonCloseLabel: 'Cancel' } });
      }
      else if (this.SelectedConvictions.length == 0) {
        const dialogRef = this.dialog.open(MessageDialogComponent, { width: '600px', data: { header: 'Error', message: 'No Convictions have been selected. Please select the Conviction you wish to link.', buttonOk: true, buttonClose: false, action: true, buttonOkLabel: 'Close', buttonCloseLabel: 'Cancel' } });
      }

      else {
        //if (this.SelectedWithdrawals[0].withdrawalAcdCode != this.SelectedConvictions[0].convictionAcdCode) {
        //  const dialogRef = this.dialog.open(MessageDialogComponent, { width: '600px', data: { header: 'Error', message: this.SelectedConvictions[0].convictionAcdCode + " linked conviction ACD Code does not match withdrawal ACD Code " + this.SelectedWithdrawals[0].withdrawalAcdCode + ".", buttonOk: true, buttonClose: false, action: true, buttonOkLabel: 'Close', buttonCloseLabel: 'Cancel' } });
        //}

        //else {

        this.spinner.show();
        try {
          this.SelectedConvictions.forEach(g => {
            this.LinkConvictionWithdrawalList.push({ DriverState: DriverState, DriverLicense: DriverLicense, WithdrawalID: this.SelectedWithdrawals[0].withdrawalId, ConvictionID: g.convictionId });
          });
        }
        catch (Exception) {
          console.log("Array Error" + Exception);
        }
        this.http.post<boolean>(this.baseUrl + 'api/Data/InsertConvictionWithdrawalLink', this.LinkConvictionWithdrawalList).subscribe(
          data => {
            if (!data) {
              this.isError = true;
              this.LinkConvictionWithdrawalList.splice(0, this.LinkConvictionWithdrawalList.length);
              this.errorMessage = "ERROR";
              this.spinner.hide();
              window.scrollTo(0, 0);
            }
            else {
              this.isError = false;
              this.LinkConvictionWithdrawalList.splice(0, this.LinkConvictionWithdrawalList.length);
              this.spinner.hide();
              this.getDriverDetail(false);
            }
          },
          error => {
            this.LinkConvictionWithdrawalList.splice(0, this.LinkConvictionWithdrawalList.length);
            this.spinner.hide();
          }
        );
        //}
      }
    }
  }
  public RemoveWithdrawalLinks(DriverLicense: string, DriverState: string) {
    this.authService.currentUser.subscribe(g => {
      if (g != null) {
        var Account = new LoginModel();
        Account.Password = "xxxxxxxxx";
        Account.UserID = g.userID;
        this.userInfo = this.http.post<User>(this.baseUrl + 'login/Account/RefreshToken', Account);
        this.userInfo.subscribe(g => {
          localStorage.removeItem('currentUser');
          localStorage.setItem('currentUser', JSON.stringify(g));
          this.idleTimeoutSvc.resetTimer();
        });
      }
    });

    //#BUG 5595 FIXED check and see if HQ account is updating on PRODUCTION       
    var userState = this.authService.currentUserValue.state;
    var env = this.authService.currentUserValue.environment;

    if (userState.toUpperCase() == "HQ") {
      this.driverInformation.errorMessage = "HQ users are unable to create convictions, withdrawals or negates.";
      this.driverInformation.isError = true;
      window.scrollTo(0, 0);
    }
    else {
      if (this.SelectedWithdrawals.length <= 0) {
        const dialogRef = this.dialog.open(MessageDialogComponent, { width: '600px', data: { header: 'Error', message: 'No Withdrawals have been selected. Please select the Withdrawal from which you want to remove the link(s).', buttonOk: true, buttonClose: false, action: true, buttonOkLabel: 'Close' } });
      }
      else {
        this.spinner.show();
        this.SelectedWithdrawals.forEach(g => { this.WithdrawalLinks.push({ DriverState: DriverState, DriverLicense: DriverLicense, WithdrawalID: g.withdrawalId, ConvictionID: "" }); });
        this.http.post<boolean>(this.baseUrl + 'api/Data/RemoveAllWithdrawalLinks', this.WithdrawalLinks).subscribe(
          data => {
            if (!data) {
              this.isError = true;
              this.errorMessage = "ERROR";
              this.spinner.hide();
              window.scrollTo(0, 0);
            }
            else {
              this.isError = false;
              this.spinner.hide();
              this.getDriverDetail(false);
            }
            this.SelectedWithdrawals.splice(0, this.SelectedWithdrawals.length - 1);
          },
          error => {
            this.spinner.hide();
            this.SelectedWithdrawals.splice(0, this.SelectedWithdrawals.length - 1);

          }
        );
      }
    }   
  }
  public deleteNegateWithdrawal(driverLicenseNumber: string, driverState: string, element: HTMLElement) {
    this.authService.currentUser.subscribe(g => {
      if (g != null) {
        var Account = new LoginModel();
        Account.Password = "xxxxxxxxx";
        Account.UserID = g.userID;
        this.userInfo = this.http.post<User>(this.baseUrl + 'login/Account/RefreshToken', Account);
        this.userInfo.subscribe(g => {
          localStorage.removeItem('currentUser');
          localStorage.setItem('currentUser', JSON.stringify(g));
          this.idleTimeoutSvc.resetTimer();
        });
      }
    });

    //#BUG 5595 FIXED check and see if HQ account is updating on PRODUCTION       
    var userState = this.authService.currentUserValue.state;
    var env = this.authService.currentUserValue.environment;

    if (userState.toUpperCase() == "HQ") {
      this.driverInformation.errorMessage = "HQ users are unable to create convictions, withdrawals or negates.";
      this.driverInformation.isError = true;
      window.scrollTo(0, 0);
    }
    else {
      if (this.SelectedWithdrawals.length == 1) {
        if (this.showCommentDropDownW && this.WithdrawalCommentFormControl.value == "" && this.LoginState == "MI") {
          this.WithdrawalCommentFormControl.setValidators([invalidElemValidator]);
          this.WithdrawalCommentFormControl.updateValueAndValidity();
          this.WithdrawalCommentFormControl.markAsTouched();
          element.scrollIntoView();
        }
        else if (/*this.commentTextW &&*/ this.showCommentDropDownW && this.WithdrawalCommentFormControl.value == "" && this.LoginState == "OR" /*&& (this.commentsFormWithdrawalText.value == "" || this.commentsFormWithdrawalText.invalid)*/) {
          this.WithdrawalCommentFormControl.setValidators([invalidElemValidator]);
          this.WithdrawalCommentFormControl.updateValueAndValidity();
          this.WithdrawalCommentFormControl.markAsTouched();
          //this.commentsFormWithdrawalText.markAsTouched();
          element.scrollIntoView();
        }
        else if (this.showCommentDropDownW && this.WithdrawalCommentFormControl.value == "" && this.LoginState == "CA") {
          this.WithdrawalCommentFormControl.setValidators([invalidElemValidator]);
          this.WithdrawalCommentFormControl.updateValueAndValidity();
          this.WithdrawalCommentFormControl.markAsTouched();
          element.scrollIntoView();
        }
        else if (!this.showCommentDropDownW && this.commentTextW && this.commentsFormWithdrawalText.value == "") {
          this.commentsFormWithdrawalText.setValidators([invalidElemValidator]);
          this.commentsFormWithdrawalText.updateValueAndValidity();
          this.commentsFormWithdrawalText.markAsTouched();
          element.scrollIntoView();
        }
        else {
          this.WithdrawalCommentFormControl.clearValidators();
          this.WithdrawalCommentFormControl.updateValueAndValidity();
          this.commentsFormWithdrawalText.clearValidators();
          this.commentsFormWithdrawalText.updateValueAndValidity();

          const dialogRef = this.dialog.open(MessageDialogComponent, { width: '400px', data: { header: 'Confirmation', message: 'Are you sure you want to delete/negate the withdrawal?', buttonOk: true, buttonClose: true, action: true, buttonOkLabel: 'Continue', buttonCloseLabel: 'Cancel' } });
          dialogRef.afterClosed().subscribe(result => {
            if (result == true && result != undefined) {
              this.spinner.show();
              this.UAS.currentUser.subscribe(message => { this.driverInformation.userID = message; });

              this.driverInformation.driverState = driverState;
              this.driverInformation.driverLicense = driverLicenseNumber;
              this.driverInformation.withdrawalData = this.SelectedWithdrawals[0];
              this.driverInformation.withdrawalData.withdrawalHistoryComments = (this.withdrawalComments + " " + this.commentsFormWithdrawalText.value).trim();

              this.http.post<DriverDetailInformation>(this.baseUrl + 'api/Data/NegateWithdrawal', this.driverInformation).subscribe(
                data => {
                  this.spinner.hide();
                  if (data.isError) {
                    window.scrollTo(0, 0);
                    this.isError = data.isError;
                    this.errorMessage = data.errorMessage;
                    this.driverInformation = data;
                  }
                  else {
                    this.commentsFormWithdrawalText = new FormControl('', [Validators.maxLength(500)]);
                    this.WithdrawalCommentFormControl.setValue('');
                    for (let i in this.SelectedWithdrawals) {
                      this.SelectedWithdrawals.pop();
                    }
                    this.SelectedWithdrawals = null;
                    this.getDriverDetail(false);
                  }
                },
                error => {
                  this.spinner.hide();
                }
              );
            }
            else {
              this.WithdrawalCommentFormControl.clearValidators();
              this.WithdrawalCommentFormControl.updateValueAndValidity();
            }
          });


        }
      }
      else if (this.SelectedWithdrawals.length == 0) {
        const dialogRef = this.dialog.open(MessageDialogComponent, { width: '400px', data: { header: 'Error', message: 'No Withdrawals have been selected. Please select the Withdrawal you wish to negate/delete.', buttonOk: true, buttonClose: false, action: true, buttonOkLabel: 'Close', buttonCloseLabel: 'Cancel' } });
      }
      else {
        const dialogRef = this.dialog.open(MessageDialogComponent, {
          width: '400px', data: {
            header: 'Error', message: 'Multiple Withdrawals have been selected. Please select only one Withdrawal you wish to negate/delete.', buttonOk: true, buttonClose: false, action: true, buttonOkLabel: 'Close'
          }
        });
      }
    }   
  }

  public updateConviction(driverLicenseNumber: string, driverState: string) {
    if (this.SelectedConvictions.length > 0) {
      this.authService.currentUser.subscribe(g => {
        if (g != null) {
          var Account = new LoginModel();
          Account.Password = "xxxxxxxxx";
          Account.UserID = g.userID;
          this.userInfo = this.http.post<User>(this.baseUrl + 'login/Account/RefreshToken', Account);
          this.userInfo.subscribe(g => {
            localStorage.removeItem('currentUser');
            localStorage.setItem('currentUser', JSON.stringify(g));
          });
        }
      });
      this.driverInformation.driverState = driverState;
      this.driverInformation.driverLicense = driverLicenseNumber;
      this.driverInformation.convictionData = this.SelectedConvictions[0];
      this.driverInformation.action = "Update Conviction";
      this.uiService.SendConviction(this.driverInformation);
      this.router.navigateByUrl(`${"/updateConviction"}`);
    }

  }

  public updateWithdrawal(driverLicenseNumber: string, driverState: string) {
    this.authService.currentUser.subscribe(g => {
      if (g != null) {
        var Account = new LoginModel();
        Account.Password = "xxxxxxxxx";
        Account.UserID = g.userID;
        this.userInfo = this.http.post<User>(this.baseUrl + 'login/Account/RefreshToken', Account);
        this.userInfo.subscribe(g => {
          localStorage.removeItem('currentUser');
          localStorage.setItem('currentUser', JSON.stringify(g));
          this.idleTimeoutSvc.resetTimer();
        });
      }
    });
    if (this.DataChanged && !this.IsDisabled) {
      const dialogRef = this.dialog.open(MessageDialogComponent, { width: '400px', data: { header: 'Confirmation', message: 'Driver information has been changed. Do you want to save this change?', buttonOk: true, buttonClose: true, action: true, buttonOkLabel: 'Yes', buttonCloseLabel: 'No' } });
      dialogRef.afterClosed().subscribe(result => {
        if (result == true && result != undefined) {
          if (this.DriverLastName.valid && this.DOBFormControl.valid) {
            this.UpdateDriverInfo();
            if (this.SelectedWithdrawals.length == 1) {
              this.spinner.show();
              this.UAS.currentUser.subscribe(message => { this.driverInformation.userID = message; });
              this.driverInformation.driverState = driverState;
              this.driverInformation.driverLicense = driverLicenseNumber;
              this.driverInformation.withdrawalData = this.SelectedWithdrawals[0];
              this.driverInformation.action = "Update Withdrawal";
              this.uiService.sendWithdrawal(this.driverInformation);
              this.router.navigateByUrl(`${"/updateWithdrawal"}`).then(e => {
                if (e) {
                  this.spinner.hide();
                } else {
                  this.spinner.hide();
                }
              });
            }
            else {
              const dialogRef = this.dialog.open(MessageDialogComponent, { width: '400px', data: { header: 'Error', message: 'Please only select one withdrawal to update at once.', buttonOk: true, buttonClose: false, action: true, buttonOkLabel: 'Close', buttonCloseLabel: 'Cancel' } });
            }
          }
          else {
            window.scrollTo(0, 0);
          }
        }
        else {
          if (this.SelectedWithdrawals.length == 1) {
            this.spinner.show();
            this.UAS.currentUser.subscribe(message => { this.driverInformation.userID = message; });
            this.driverInformation.driverState = driverState;
            this.driverInformation.driverLicense = driverLicenseNumber;
            this.driverInformation.withdrawalData = this.SelectedWithdrawals[0];
            this.driverInformation.action = "Update Withdrawal";
            this.uiService.sendWithdrawal(this.driverInformation);
            var myurl = `${"/updateWithdrawal"}`;
            this.router.navigateByUrl(myurl).then(e => {
              if (e) {
                this.spinner.hide();
              } else {
                this.spinner.hide();
              }
            });
          }
          else {
            const dialogRef = this.dialog.open(MessageDialogComponent, { width: '400px', data: { header: 'Error', message: 'Please only select one withdrawal to update at once.', buttonOk: true, buttonClose: false, action: true, buttonOkLabel: 'Close', buttonCloseLabel: 'Cancel' } });

          }
        }
      });
    }
    else {
      if (this.SelectedWithdrawals.length == 1) {
        this.spinner.show();
        this.UAS.currentUser.subscribe(message => { this.driverInformation.userID = message; });
        this.driverInformation.driverState = driverState;
        this.driverInformation.driverLicense = driverLicenseNumber;
        this.driverInformation.withdrawalData = this.SelectedWithdrawals[0];
        this.driverInformation.action = "Update Withdrawal";
        this.uiService.sendWithdrawal(this.driverInformation);
        var myurl = `${"/updateWithdrawal"}`;
        this.router.navigateByUrl(myurl).then(e => {
          if (e) {
            this.spinner.hide();
          } else {
            this.spinner.hide();
          }
        });
      }
      else {
        const dialogRef = this.dialog.open(MessageDialogComponent, { width: '400px', data: { header: 'Error', message: 'Please only select one withdrawal to update at once.', buttonOk: true, buttonClose: false, action: true, buttonOkLabel: 'Close', buttonCloseLabel: 'Cancel' } });

      }
    }
  }

  public SelectConviction(data: conviction) {
    const index: number = this.SelectedConvictions.findIndex(g => g.convictionId == data.convictionId);
    if (index !== -1) {
      this.SelectedConvictions.splice(index, 1);
      if (this.SelectedConvictions.length == 0) {
        this.ConvictionCommentFormControl.clearValidators();
        this.ConvictionCommentFormControl.updateValueAndValidity();
        this.commentsFormConvictionText.clearValidators();
        this.commentsFormConvictionText.updateValueAndValidity();
      }
    }
    else {
      this.SelectedConvictions.push(data);
    }
  }

  public SelectWithdrawal(data: withdrawal) {
    var index = -1;
    for (var i = 0; i < this.SelectedWithdrawals.length; i++) {
      if (this.SelectedWithdrawals[i].withdrawalId == data.withdrawalId) {
        index = i;
      }
    }
    if (index !== -1) {
      this.SelectedWithdrawals.splice(index, 1);
      if (this.SelectedWithdrawals.length == 0) {
        this.WithdrawalCommentFormControl.clearValidators();
        this.WithdrawalCommentFormControl.updateValueAndValidity();
        this.commentsFormWithdrawalText.clearValidators();
        this.commentsFormWithdrawalText.updateValueAndValidity();
      }
    }
    else {
      this.SelectedWithdrawals.push(data);
    }
  }

  public scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  goBack(): void {
    this.location.back();
  }

  driverCountry = new FormControl({ value: '', disabled: false }, [
    Validators.required
  ]);
  commentsFormControl = new FormControl('', []);

  commentsFormWithdrawalText = new FormControl('', [Validators.maxLength(500)]);
  commentsFormConvictionText = new FormControl('', [Validators.maxLength(500)]);
  WithdrawalCommentFormControl = new FormControl('', []);
  ConvictionCommentFormControl = new FormControl('', []);
  DOBFormControl = new FormControl({ value: '', disabled: false }, [Validators.required, dateErrorValidator]);
  DOBForm = new FormControl('', []);
  DriverLastName = new FormControl({ value: '', disabled: false }, [Validators.required]);
  DriverFirstName = new FormControl({ value: '', disabled: false });
  DriverMiddleName = new FormControl({ value: '', disabled: false });
  DriverSuffix = new FormControl({ value: '', disabled: false });
  StreetAddr1 = new FormControl({ value: '', disabled: false });
  StreetAddr2 = new FormControl({ value: '', disabled: false });
  City = new FormControl({ value: '', disabled: false });
  County = new FormControl({ value: '', disabled: false });
  Country = new FormControl({ value: '', disabled: false });
  State = new FormControl({ value: '', disabled: false });
  Zip = new FormControl({ value: '', disabled: false });
}

export class State {
  jurisdictionCode: string;
  jurisdictionDesc: string;
  jurisdictionGroup: string;
}
interface Country {
  jurisdictionCode: string;
  jurisdictionDesc: string;
  type: string;
}

export class Comment {
  id: string;
  description: string;
  freeFormat: boolean;
}

export class LinkConvictionWithdrawalDTO {
  DriverState: string;
  DriverLicense: string;
  ConvictionID: string;
  WithdrawalID: string;
}
